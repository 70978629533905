<template>
  <div class="subsidyList">
    <!-- 通过弹窗 -->
    <el-dialog title="审批通过" :visible.sync="showy" width="20%" append-to-body>
      <div class="tongyi">
        <div class="topbox">
          <span>审批意见：</span>
          <input type="text" v-model="idea" @input="callApiOnBlur" />
        </div>
        <div class="footerbox">
          <div v-if="showy" class="img">
            <vue-qr ref="qrCode" :text="text" width="200" height="200"></vue-qr>
          </div>
          <!-- <el-button type="primary" @click="regenerateQRCode()">
                  重新生成
                </el-button> -->

          <!-- <img src="../../../assets/acquiesce.png" alt="加载失败" /> -->
          <h1 style="color: #ccc">请使用微信扫描后签字提交</h1>
        </div>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="showy = false">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog title="审批不通过" :visible.sync="onshow" width="30%" append-to-body :before-close="handleClose">
      <!-- 展示上传的图片 -->
      <div class="tuihui">
        <div class="leftbox">退回说明</div>
        <div class="rightbox">
          <el-input type="textarea" :rows="8" placeholder="请输入内容" v-model="title">
          </el-input>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onshow = false">取 消</el-button>
        <el-button type="primary" @click="submitBack">退 回</el-button>
      </span>
    </el-dialog>

    <div class="title">
      <h3>农机购置申报</h3>
      <div class="backBtn">
        <el-button type="primary" @click="back">返回</el-button>
      </div>
    </div>

    <TablePage :data="tableData" :columns="columns" :defaultSelectList="defaultSelectList" :loading="loading"
      :pagination="false" :border="true" @handSelectAll="handSelectAll" @handSelect="handSelect">
      <template slot="orderState" slot-scope="scope">
        <el-button v-if="!scope.row.operation" type="text" size="small" @click="detail(scope.row)">详情</el-button>
        <el-button v-if="!scope.row.operation" type="text" size="small" @click="upLook(scope.row)">退回</el-button>
        <el-button v-if="!scope.row.operation" type="text" size="small" @click="upLook(scope.row)">通过</el-button>
      </template>
    </TablePage>

    <div class="footer">
      <el-button type="primary" @click="toBack()">批量退回</el-button>
      <el-button type="primary" @click="toPass()">批量通过</el-button>
    </div>
  </div>
</template>

<script>
  import VueQr from "vue-qr";
  const Url = window.globalUrl.api2;
  import { rejectWorkflow } from "@/api/snbt";
  import { selectRunWorkflow } from '@/api/subsidy'
  import TablePage from "@/components/table/Approve-page";
  import { subsidyListColumn } from "../../tableColumn"
  export default {
    components: {
      TablePage,
    },
    data() {
      return {
        loading: false,
        tableData: [],
        columns: subsidyListColumn,
        defaultSelectList: [],//默认选中的列表
        showy: false,// 审批通过弹窗
        onshow: false, // 审批不通过弹窗
        idea: "", // 审批意见
        title: "", // 退回原因
        backObj: {},//退回的对象
      }
    },
    mounted() {
      this.selectRunWorkflow()

    },
    methods: {
      //审批通过
      handleClose() {
        this.showy = false
        this.showy = false
        this.backObj = {}
      },
      // 全选或者反选
      handSelectAll(selection) {
        // console.log(selection, "全选");
        this.selection = selection
      },
      //多选
      handSelect(selection) {
        // console.log(selection, "单选");
        this.selection = selection
      },
      // //批量通过审核
      async toPass() {
        let allTaskIds = []
        this.selection.forEach(item => {
          allTaskIds.push({
            declareId: item.declareId,
            taskId: item.taskId,
          })
        })
        // console.log(allTaskIds,'查看params')
        let res = await redisWorkflow(allTaskIds)
        this.key = res.data;
        this.callApiOnBlur()
        this.showy = true
      },
      toBackOne(row) {
        this.onshow = true
        this.backObj = row
      },
      //点击批量退回
      toBack() {
        this.onshow = true
      },
      //批量退回接口
      submitBack() {
        // console.log(this.backObj,'查看退回原因')
        if (this.backObj && Object.keys(this.backObj).length > 0) {
          let allTaskIds = []
          allTaskIds.push({
            agreeReason: this.title ? this.title : '',
            agreePhoto: this.backObj.sign,
            declareId: this.backObj.declareId,
            taskId: this.backObj.taskId,
          })
          this.reject(allTaskIds);
        } else {
          // console.log(this.selection,'查看退回的对象')
          let allTaskIds = []
          this.selection.forEach(item => {
            allTaskIds.push({
              agreeReason: this.title ? this.title : '',
              agreePhoto: item.sign,
              declareId: item.declareId,
              taskId: item.taskId,
            })
          })
          this.reject(allTaskIds);
        }

      },
      // 拒绝
      async reject(params) {
        let res = await rejectWorkflow(params);
        if (res.code == 0) {
          this.$message.success("操作成功");
          this.handleClose();
          this.selectRunWorkflow();
        } else {
          this.$message.error(res.msg);
        }
        console.log(res);
      },
      // 重新生成二维码
      callApiOnBlur() {
        let identityObj = JSON.parse(localStorage.getItem("identityObj"));
        // 提取所有的 taskIds
        this.text = `${Url}?key=${this.key}/identityId=${identityObj.content.identityId}/agreeReason=${this.idea}`;
        console.log(this.text, '查看this.text')
      },
      // 详情
      detail(row) {
        console.log(row.declareId, '查看declareId')
        this.$router.push({ path: "/memberCenter/snbt/snbtbl/components/subsidyListDetail", query: { declareId: row.declareId }, })
      },

      handleSelection(selects) {
        console.log(selects);
      }, // 全选或者反选
      handSelectAll(selection) {
        this.defaultSelectList = selection;
        // console.log(selection, "全选");
      },
      //多选
      handSelect(selection) {
        this.defaultSelectList = selection;
        // console.log(selection, "多选");
      },
      // 待审批列表
      async selectRunWorkflow() {
        let params = {
          subsidyId: this.$route.query.subsidyId
        }
        let res = await selectRunWorkflow(params)
        this.tableData = res.data
        this.defaultSelectList = res.data
      },
      // 批量修改
      async BatchModification() {

        let declareId = this.defaultSelectList.map(item => item.declareId)
        let taskId = this.defaultSelectList.map(item => item.taskId)
        // let params={
        //   machineryPurchaseDtos:{
        //     agreePhoto:'',
        //     agreeReason:'',
        //     declareId:JSON.parse(declareId) ,
        //     taskId:JSON.stringify(taskId),
        //   }
        // }
        console.log(declareId, taskId, '批量修改')
        // console.log(this.defaultSelectList,'批量修改')
        // /identityId=${identityObj.content.identityId}
        this.text = `${Url}?declareId=${JSON.stringify(declareId)}/taskId=${JSON.stringify(taskId)}`;
        console.log(this.text);
      },
      // 批量退回
      async BatchReturn() {
        let params = {

        }
        let res = await rejectWorkflow(params)
      },
      back() {
        this.$router.go(-1); // 返回上一级路由
      },
    }
  }
</script>

<style lang="less" scoped>
  .subsidyList {
    .title {
      display: flex;
      justify-content: space-between;
    }
  }

  .tongyi {
    box-sizing: border-box;

    .topbox {
      span {
        width: 30%;
        font-size: 20px;
        // font-weight: 400;
        color: #000;
      }

      input {
        width: 60%;
        font-size: 20px;
      }
    }

    .footerbox {
      width: 100%;
      text-align: center;
      margin-top: 20px;

      .img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .tuihui {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #000;

    .leftbox {
      width: 20%;
      height: 200px;
      text-align: center;
      line-height: 200px;
      font-size: 22px;
      border-right: 1px solid #000;
    }

    .rightbox {
      width: 80%;
      // height: 200px;

      // border-right: 1px solid #000;
    }
  }
</style>
